import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { loginAction, loginError, logout } from '../ducks/loginSlice'
import { RootState } from '../../../store/store'
import { useNavigate } from 'react-router-dom'
import { LoginArea, FirstLoginBox, ErrorContainer } from './styles'
import { confirmSignIn } from 'aws-amplify/auth'
import { TextInput } from '../../../shared/components/input/TextInput'
import { isEqual } from 'lodash'
import Button from '../../../shared/components/button/Button'
import { ButtonVariant } from '../../../shared/components/button/constants'

const FirstLoginForm: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const isLoggedIn = useSelector((state: RootState) => state.login.isLoggedIn)
  const user = useSelector((state: RootState) => state.login.user)
  const errorMessage = useSelector((state: RootState) => state.login.errorMsg)

  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [passwordErrors, setPasswordErrors] = useState<string[]>([])
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('')

  const validatePassword = (password: string): string[] => {
    const errors = []
    if (password.length < 12)
      errors.push('Password must be at least 12 characters long.')
    if (!/[A-Z]/.test(password))
      errors.push('Password must contain at least one uppercase letter.')
    if (!/[a-z]/.test(password))
      errors.push('Password must contain at least one lowercase letter.')
    if (!/\d/.test(password))
      errors.push('Password must contain at least one number.')
    if (password !== confirmPassword)
      errors.push('Password must match the confirm password.')
    return errors
  }

  useEffect(() => {
    if (user.first_login && errorMessage) dispatch(logout())
    if (isLoggedIn && user.first_login) return
    if (isLoggedIn && user.accessToken) navigate('/organization')
    if (!isLoggedIn) navigate('/login')
  }, [isLoggedIn, navigate, user.first_login, user.accessToken])

  useEffect(() => {
    setIsLoading(false)
  }, [errorMessage])

  const validateNewPassword = async () => {
    setIsLoading(true)
    clearErrorMessage()

    const errors = validatePassword(password)
    if (errors.length > 0) {
      setPasswordErrors(errors)
      setIsLoading(false)
      return
    }

    if (
      isLoggedIn &&
      user.first_login &&
      user.email &&
      user.roles.length === 0 &&
      isEqual(password, confirmPassword)
    ) {
      confirmSignIn({ challengeResponse: password })
        .then(async e => {
          dispatch(loginAction(user?.email, password, true))
          setIsLoading(false)
          return
        })
        .catch(e => {
          dispatch(loginError('Failed to update the password, login again.'))
          console.log({ error: e })
          setIsLoading(false)
          return
        })
      setIsLoading(false)
      return
    }
    dispatch(loginError('Failed to update the password'))
    setIsLoading(false)
  }

  const clearErrorMessage = () => {
    dispatch(loginError(''))
    setPasswordErrors([])
    setConfirmPasswordError('')
  }

  const onConfirmPasswordInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setConfirmPasswordError('')
    setConfirmPassword(e.target.value)
  }

  const onPasswordInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setPasswordErrors([])
    setPassword(e.target.value)
  }

  return (
    <FirstLoginBox>
      <h2>Welcome, {user.username}!</h2>
      <span>
        Now it's time to create a password of your own to activate your account.
      </span>
      <span>Think of a new and secure password for your Mika account.</span>
      <LoginArea>
        <TextInput
          label="Your new password"
          type="password"
          placeholder="Password*"
          value={password}
          onChange={onPasswordInputChange}
          hasError={passwordErrors.length > 0}
        />
        <TextInput
          label="Confirm your new password"
          type="password"
          placeholder="Password*"
          value={confirmPassword}
          onChange={onConfirmPasswordInputChange}
          hasError={passwordErrors.length > 0}
        />
        {confirmPasswordError && (
          <div className="text-danger">{confirmPasswordError}</div>
        )}
        {!isLoading ? (
          <Button onClick={validateNewPassword} variant={ButtonVariant.FILLED}>
            Activate account
          </Button>
        ) : (
          <Button
            onClick={validateNewPassword}
            disabled={isLoading}
            variant={ButtonVariant.FILLED}
          >
            <div className="spinner-border text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </Button>
        )}
        {!!errorMessage && (
          <div className="alert alert-danger" role="alert">
            <div>
              <i className="bi bi-exclamation-triangle-fill" />

              <span>{errorMessage}</span>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
              onClick={clearErrorMessage}
            ></button>
          </div>
        )}
      </LoginArea>
      {passwordErrors.length > 0 && (
        <ErrorContainer className="error-messages">
          {passwordErrors.map((error, index) => (
            <li key={index} className="text-danger">
              {error}
            </li>
          ))}
        </ErrorContainer>
      )}
    </FirstLoginBox>
  )
}

export default FirstLoginForm
