import React from 'react'
import { CommonLogo } from './Logos'
import { Navbar, SektorNavbar } from './LayoutTemplate'
import { isSektorEnv } from '../../App'

export const Header = ({
  showLogo = true,
  children,
}: {
  showLogo?: boolean,
  children?: React.ReactElement
}): React.ReactNode => {
  const NavbarComponent = isSektorEnv ? SektorNavbar : Navbar
  return (
    <NavbarComponent>
      <a href="/">{showLogo && <CommonLogo />}</a>
      {children}
    </NavbarComponent>
  )
}
