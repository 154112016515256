import React, { useState } from 'react'
import {
  resetPassword,
  confirmResetPassword,
  AuthError,
} from 'aws-amplify/auth'
import { useNavigate, useParams } from 'react-router-dom'
import { isValidEmail } from '../../shared/utils'
import { styled } from 'styled-components'
import { LoginHeader } from '../Login/LoginHeader'
import { TextInput } from '../../shared/components/input/TextInput'
import style from './PasswordReset.module.css'
import Button from '../../shared/components/button/Button'
import {
  ButtonSize,
  ButtonVariant,
} from '../../shared/components/button/constants'
import { Icon } from '../../shared/components/icon/Icon'
import { createAlert } from '../../shared/components/alert/ducks/alertSlice'
import { useDispatch } from 'react-redux'
import LoadingOverlay from '../../shared/components/LoadingOverlay'
import { SektorBackground } from '../Login/Styles'
import { isSektorEnv } from '../../App'
import { LoginTitle } from '../Login/components/LoginForm'

const text = [
  {
    title: 'Forgot your password?',
    description:
      'Enter your email so we can send your password reset instructions. Make sure to check your spam folder if you don’t see it.',
    button: 'Send reset instructions',
  },
  {
    title: 'Set your new password',
    description:
      "We have sent a verification code to your email. Make sure to check your spam folder if you don't see it.",
    button: 'Save new password',
  },
  {
    title: 'Create your password',
    description:
      'To improve your security you will need to create a new password to log in to the portal, We have sent a verification code to your email.',
    button: 'Save new password',
  },
]

export default function PasswordReset() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [code, setCode] = useState('')
  const [pw1, setPw1] = useState('')
  const [isPw1Visible, setIsPw1Visible] = useState(false)
  const [pw2, setPw2] = useState('')
  const [isPw2Visible, setIsPw2Visible] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [step, setStep] = useState(0)
  const [isValid, setIsValid] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [forceReset, setForceReset] = useState(false)

  const [codeErrors, setCodeErrors] = useState<string[]>([])
  const [passwordErrors, setPasswordErrors] = useState<string[]>([])

  const params = useParams()
  const { userEmail } = params

  React.useEffect(() => {
    if (isValidEmail(userEmail)) {
      setEmail(userEmail)
      handleResetPassword(userEmail)
      setForceReset(true)
    }
  }, [])

  const validateForm = () => {
    const codErrors = []
    if (code === '') codErrors.push('Please enter a verification code')

    const pwErrors = []
    if (pw1.length < 12)
      pwErrors.push('Password must be at least 12 characters long.')
    if (!/[A-Z]/.test(pw1))
      pwErrors.push('Password must contain at least one uppercase letter.')
    if (!/[a-z]/.test(pw1))
      pwErrors.push('Password must contain at least one lowercase letter.')
    if (!/\d/.test(pw1))
      pwErrors.push('Password must contain at least one number.')
    if (pw1 !== pw2) pwErrors.push('Password must match the confirm password.')

    if (codErrors.length > 0) {
      setIsValid(false)
      setCodeErrors(codErrors)
      return false
    }
    if (pwErrors.length > 0) {
      setIsValid(false)
      setPasswordErrors(pwErrors)
      return false
    }

    setIsValid(true)
    return true
  }

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value)
    setIsValid(isValidEmail(e.target.value))
  }

  async function handleResetPassword(email: string) {
    setIsLoading(true)

    try {
      setErrorMessage('')
      if (step === 0) {
        await resetPassword({ username: email })
        setStep(1)
      } else if (step === 1) {
        if (!validateForm()) return

        await confirmResetPassword({
          username: email,
          confirmationCode: code,
          newPassword: pw1,
        })
        dispatch(
          createAlert({
            level: 'success',
            message: 'You can now log in with your new password',
          }),
        )
        navigate('/')
      }
    } catch (error) {
      if (error instanceof AuthError) {
        if (error.name === 'CodeMismatchException') {
          dispatch(
            createAlert({
              level: 'error',
              message: 'Invalid verification code',
            }),
          )
          setCodeErrors(['Invalid verification code'])
        }
      }

      setErrorMessage(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  async function requestNewCode() {
    setIsLoading(true)
    await resetPassword({ username: email })
    dispatch(
      createAlert({
        level: 'success',
        message: 'We sent you a new verification code',
      }),
    )
    setIsLoading(false)
  }

  const handlePasswordChange = (pw1: string) => {
    setPasswordErrors([])
    setPw1(pw1)
  }
  const handleConfirmPasswordChange = (pw2: string) => {
    setPw2(pw2)
  }
  const handleCodeChange = (code: string) => {
    setCodeErrors([])
    setCode(code)
  }

  const BgComponent = isSektorEnv ? SektorBackground : Background

  return (
    <BgComponent>
      <LoadingOverlay isLoading={isLoading} />
      <LoginHeader />
      <Content>
        <Title>
          <LoginTitle />
        </Title>
        <Box>
          <BoxTitle>
            {forceReset === false ? text[step].title : text[2].title}
          </BoxTitle>
          <BoxDescription>
            {forceReset === false
              ? text[step].description
              : text[2].description}
          </BoxDescription>
          <div className={style.input}>
            {step === 0 && (
              <>
                <TextInput
                  label="Your email"
                  name="email"
                  placeholder=""
                  type="text"
                  onChange={onEmailChange}
                  value={email}
                  hasError={!!errorMessage}
                  info={errorMessage}
                  infoType={errorMessage ? 'error' : null}
                />
                <Button
                  size={ButtonSize.MEDIUM}
                  variant={ButtonVariant.FILLED}
                  onClick={() => handleResetPassword(email)}
                  disabled={!isValid}
                >
                  {text[step].button}
                  <Icon name="arrow_right.svg" />
                </Button>
              </>
            )}
            {step === 1 && (
              <>
                <TextInput
                  label="Verification code"
                  name="code"
                  placeholder=""
                  type="text"
                  onChange={e => handleCodeChange(e.target.value)}
                  value={code}
                  hasError={codeErrors.length > 0}
                />
                <TextInput
                  label="Your new password"
                  name="pw1"
                  placeholder=""
                  suffix={
                    <div
                      className={style.icon}
                      onClick={() => setIsPw1Visible(!isPw1Visible)}
                    >
                      <Icon name={isPw1Visible ? 'eye_slash.svg' : 'eye.svg'} />
                    </div>
                  }
                  type={isPw1Visible ? 'text' : 'password'}
                  onChange={e => handlePasswordChange(e.target.value)}
                  hasError={passwordErrors.length > 0}
                  value={pw1}
                />
                <TextInput
                  label="Confirm your new password"
                  name="pw2"
                  placeholder=""
                  suffix={
                    <div
                      className={style.icon}
                      onClick={() => setIsPw2Visible(!isPw2Visible)}
                    >
                      <Icon name={isPw2Visible ? 'eye_slash.svg' : 'eye.svg'} />
                    </div>
                  }
                  type={isPw2Visible ? 'text' : 'password'}
                  onChange={e => handleConfirmPasswordChange(e.target.value)}
                  hasError={passwordErrors.length > 0}
                  value={pw2}
                />
                <Button
                  size={ButtonSize.MEDIUM}
                  variant={ButtonVariant.FILLED}
                  onClick={() => handleResetPassword(email)}
                >
                  {text[step].button}
                  <Icon name="arrow_right.svg" />
                </Button>
                {passwordErrors.length > 0 && (
                  <ErrorContainer className="error-messages">
                    {passwordErrors.map((error, index) => (
                      <li key={index} className="text-danger">
                        {error}
                      </li>
                    ))}
                  </ErrorContainer>
                )}
                {codeErrors.length > 0 && (
                  <ErrorContainer className="error-messages">
                    {codeErrors.map((error, index) => (
                      <li key={index} className="text-danger">
                        {error}
                      </li>
                    ))}
                  </ErrorContainer>
                )}
              </>
            )}
          </div>
        </Box>
        <Footer>
          {step === 0 && (
            <>
              Remember it now?{' '}
              <Link onClick={() => navigate('/')}>Go back to log in</Link>
            </>
          )}
          {step === 1 && (
            <Link onClick={requestNewCode}>I haven't got my code</Link>
          )}
        </Footer>
      </Content>
    </BgComponent>
  )
}

export const ErrorContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 8px;
  margin: 0;
  list-style: none;
  text-align: left;
  font-size: 12px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #f7a0a0;
`

const Background = styled.div`
  background-color: var(--brand-primary);
  height: 100%;
  display: flex;
  flex-direction: column;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
  > span {
    color: var(--button-label-tonal-default);
  }
  > span.partner-portal {
    font-size: 24px;
  }

  > a {
    align-self: center;
    img {
      height: 48px;
      margin-right: 0px;
    }
  }
`

const Box = styled.div`
  display: flex;
  align-self: center;
  width: 382px;
  padding: 32px;
  margin-top: 32px;
  flex-direction: column;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.05);
`

const BoxTitle = styled.div`
  overflow: hidden;
  color: var(--content-primary);
  text-overflow: ellipsis;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  margin-bottom: 8px;
`

const BoxDescription = styled.div`
  overflow: hidden;
  color: var(--content-secondary);
  text-overflow: ellipsis;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 32px;
`

const Footer = styled.span`
  color: var(--content-primary);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-top: 16px;
`

const Link = styled.span`
  font-weight: 600;
  cursor: pointer;
`
