import { styled } from 'styled-components'
import { StyledButton } from '../../../shared/components/Button'

export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  > span {
    font-size: 44px;
    font-weight: 700;
    line-height: 54px;
    color: var(--button-label-tonal-default);
  }

  > span.partner-portal {
    font-size: 24px;
  }

  > a {
    align-self: center;
    img {
      height: 48px;
    }
  }
`

export const FirstLoginBox = styled(LoginBox)`
  padding-top: 40px;

  > h2 {
    color: var(--content-primary);
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  > span:first-of-type {
    font-weight: 600;
    margin-bottom: 16px;
  }

  > span {
    display: flex;
    margin-bottom: 8px;
    text-align: start;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`

export const LoginArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 24px;
  z-index: 2;

  a {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
  }

  .alert {
    display: flex;
    flex-direction: row;
    line-height: 22px;
    justify-content: space-between;
    font-size: 14px;
    padding: 16px;
    position: relative;

    div {
      flex-grow: 1;
      display: flex;
      flex-direction: row;
      gap: 8px;
      justify-content: flex-start;

      i {
        margin-left: 4px;
        font-size: 16px;
        font-weight: bold;
      }
      span {
        overflow-x: hidden;
        text-overflow: ellipsis;
        width: 93%;
      }
    }
  }

  input {
    line-height: 28px;
    font-size: 14px;
    padding: 8px 4px;
  }
`

export const LoginButton = styled(StyledButton)<{ disabled: boolean }>`
  margin-top: 8px;
  background: ${({ disabled }) =>
    disabled
      ? 'var(--button-label-tonal-hover)'
      : 'var(--button-label-tonal-default)'};
  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  color: var(--grey-0);

  > div {
    width: 14px;
    height: 14px;
    margin-left: 8px;
  }

  i {
    margin-left: 8px;
    font-size: 14px;
    font-weight: bold;
  }
`

export const ErrorContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: left;
  font-size: 12px;
  margin-top: 12px;
`
