export const enum RolePermission {
  CREATE = 'C',
  READ = 'R',
  UPDATE = 'U',
  DELETE = 'D',
}

export const permissions = {
  charity: {
    mikaAdmin: {
      organization: 'CRUD',
      campaign: 'CRUD',
      merchant: 'CRUD',
      user: 'CRUD',
      transactions: '',
      installation: '',
      kiosk: 'CRUD',
      reports: 'CR',
    },
    portfolioAdmin: {
      organization: 'CRUD',
      campaign: 'CRUD',
      merchant: 'CRUD',
      user: 'CRUD',
      transactions: 'R',
      installation: '',
      kiosk: 'CRUD',
      reports: 'CRUD',
    },
    servicePortfolioAdmin: {
      organization: 'CRUD',
      campaign: '',
      merchant: 'CRUD',
      user: 'CRUD',
      transactions: 'R',
      installation: 'CRUD',
      kiosk: 'CRUD',
      reports: 'CR',
    },
    orgAdmin: {
      organization: 'R',
      campaign: 'CRUD',
      merchant: 'R',
      user: 'CRUD',
      transactions: 'R',
      installation: '',
      kiosk: 'CRUD',
      reports: 'CR',
    },
    campaignAdmin: {
      organization: 'CRUD',
      campaign: 'CRUD',
      merchant: '',
      user: 'CRUD',
      transactions: 'R',
      installation: '',
      kiosk: 'CRUD',
      reports: 'CR',
    },
  },
  retail: {
    mikaAdmin: {
      organization: 'CRU',
      campaign: 'CRU',
      merchant: 'CRU',
      user: 'CRU',
      transactions: '',
      installation: '',
      kiosk: 'CRU',
      reports: 'CR',
    },
    portfolioAdmin: {
      organization: 'CRU',
      campaign: 'CRU',
      merchant: 'CRU',
      user: 'CRU',
      transactions: 'R',
      installation: 'CRUD',
      kiosk: '',
      reports: 'CRU',
    },
    servicePortfolioAdmin: {
      organization: 'CRU',
      campaign: '',
      merchant: 'CRU',
      user: 'CRU',
      transactions: 'R',
      installation: 'CRU',
      kiosk: 'CRU',
      reports: 'CR',
    },
    orgAdmin: {
      organization: 'RU',
      campaign: 'CRU',
      merchant: 'CR',
      user: 'CRU',
      transactions: 'R',
      installation: 'CRU',
      kiosk: '',
      reports: 'CR',
    },
    campaignAdmin: {
      organization: 'CRU',
      campaign: 'CRU',
      merchant: '',
      user: 'CRU',
      transactions: 'R',
      installation: '',
      kiosk: 'CRU',
      reports: 'CR',
    },
  },
}

export const hiddenFields = {
  mikaAdmin: [],
  portfolioAdmin: ['orgLogo', 'orgSignature', 'orgCharityNumber'],
  servicePortfolioAdmin: [],
  orgAdmin: [],
  campaignAdmin: [],
}
export const restrictions = {
  orgAdmin: {
    custom: 'portfolioId',
    restricted: ['campaign', 'reportsCampaign'],
  },
  portfolioAdmin: {
    custom: '',
    restricted: ['reportsCampaign', 'kiosk',],
  },
  servicePortfolioAdmin: {
    custom: '',
    restricted: ['reportsCampaign'],
  },
}
export const required = {
  orgAdmin: {
    installation: ['portfolioId'],
  },
}